import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Circular Linked List | Code Tutorial'}
			subHeader={''}
			description={'Circular Linked List is a variation of Linked list in which the first element points to the last element and the last element points to the first element.'}
			keywords={['Circular Linked List']}
			embeddedVideo={'pBaZl9B448g'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/d477f6c2209263413c23f08f5f1d3eeb/raw/16a73b923a4111f05d99ab7c745654abe179f7fe/CircularLinkedList.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
